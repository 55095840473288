.navbar {
    background-color: #013777;
    display: flex;
    justify-content: start;
    align-items: center;
    z-index: 999;
  }
  .nav-text span   {
    color:white;
  }
  .menu-bars {
  
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: #013777;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 360ms;
    z-index: 99999;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #060b26;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background-color: #013777;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }
  .flag a {
    margin-right: 500px !important;
    padding: 2px 3px 1px 3px !important;
    color: rgb(248, 0, 0);
    font-size: 10px;
    border-radius: 3px;
    font-weight: normal;
  }
  .badge {
    position: relative;
    
    padding: 0px 4px;
    opacity: 0.5;
    border-radius: 100%;
    background: red;
    color: white;
  }

 