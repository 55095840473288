@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'lato', sans-serif; 
 
}
  
.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.text-color-body {
  color: #000;
} 
.App-link {
  color:#32ab32;
}
.errmsg{
  color: red;
}
.header{
  width: 100%;
  background-color: #d3e742;
  color: #013777;
  padding:0 4px;
  height: auto;
  overflow: hidden;
 
}
a{
  text-decoration: none !important;
  color: #013777 !important;
  padding: 2px;
}
app-check{
  height: 20px;
  width: 20px;
  margin: 1%;
}

a.header {
  text-decoration: none;
  color: #fff;
  padding: 2px;
}
.logout{
  text-decoration: none;
  color: #fff;
  padding: 2px;
}
ul li {
  text-decoration: none;
  list-style-type: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-color-primary a{
  color:#000 !important;
}
.ChallengeList, country {
  color:#000;
}

.classifica {
  color:#013777 !important;
  font-weight: 600;
  font-size:18px;
}

.color-picker-page .color-picker-module,
.color-picker-popup .color-picker-module,
.color-picker-sheet-modal .color-picker-module {
    padding-left: var(--f7-safe-area-left);
    padding-right: var(--f7-safe-area-right);
}

.me {
  background: #afb72f;
  font-weight:bold;
  font-size: 20px;
  color: white;
}

.me div   {
  
  font-weight:bold;
  color: #053c7c !important;
  
}
.logo {
  padding: 0;
  display: block;
  margin: 0 auto;
  width: auto;
}
.backgroundhome {
   text-align: center;
   font-weight: 400;
  min-height: 450px;
  background:url('sfondo.jpg') ;
  background-position: top center;
  background-size: cover;
  background-repeat: repeat-y;
  background-attachment: fixed ;
}

.postnews {
  position: relative;
  padding: 0px 4px;
  opacity: 0.6;
  border-radius: 70%;
  background: red;
  color: white;
 

}

.Toastify__close-button {
  width: 5% !important;
}

.regolamento p {
  padding:  5px 10px;
  text-align: justify;
  line-height: 1.4;
}
.btn-primary {
background: #d3e742 !important;
color: #173000 !important;
border: none !important ;
}
.risultato td{
 
   
  text-align:  center;
}
.risultato th{
   
 
  text-align:  center;
}
 
@media only screen and (min-width: 660px) {
  .item-background {
    background-position: left !important;
    background-size: 15% !important;
    background-repeat: no-repeat !important;

  }
}

 